// Loading.js
import React from 'react';

const Loading = () => (
  <div className="loading-overlay">
    <div className="loading-spinner"></div>
    <p>Loading your personalized schedule <br />
       Estimated time: less than 1 minute.</p>
  </div>
);

export default Loading;